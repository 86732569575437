import { graphql, PageProps } from 'gatsby';
import React from 'react';

import { Layout, LayoutBrandData } from '../components/layout';
import { PageNotFound } from '../components/page-not-found';

type QueryData = {
  defaultBrand: LayoutBrandData;
};

// Workaround to remove flash-of-404 (https://github.com/gatsbyjs/gatsby/issues/5329)
// Also removes mysterious issue where `<Layout>` brand is not updated from default brand
// once client-side routing takes over
const isBrowser = typeof window !== 'undefined' && window;

const NotFoundPage = ({ data }: PageProps<QueryData>) => {
  if (!isBrowser) {
    return null;
  }

  return (
    <Layout brand={data.defaultBrand}>
      <PageNotFound />
    </Layout>
  );
};

export default NotFoundPage;

export const query = graphql`
  query NotFoundPageData {
    defaultBrand: brandsJson(id: { eq: "kantan" }) {
      ...LayoutBrandFragment
    }
  }
`;
